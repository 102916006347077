import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { dateTimeFormat } from "utils/format";
import { Trash } from "react-feather";

import { getCross, removeCross } from "services/api";
import { MySpinner } from "components/MySpinner";
import { Pagination } from "components/Pagination";
import useQuery from "utils/useQuery";

const SORT_FIELDS = [
  {
    label: "Дата добавления ↑",
    value: "createdAt_asc",
    by: "createdAt",
    dir: "asc",
  },
  {
    label: "Дата добавления ↓",
    value: "createdAt_desc",
    by: "createdAt",
    dir: "desc",
  },
];

export const CrossPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const query = useQuery(useLocation);
  const [cross, setCross] = useState([]);
  const [crossCount, setCrossCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    query.get("page") ? Number(query.get("page")) : 1
  );
  const getPageSize = localStorage.getItem("pageSize") ? JSON.parse(localStorage.getItem("pageSize")) : {};
  const [pageSize, setPageSize] = useState(getPageSize[pathname] || 10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(query.get("search") || "");
  const [isShowReset, setIsShowReset] = useState(false);
  const sortByDefault = query.get("sortBy")
    ? SORT_FIELDS.find(
        (val) => val.value === `${query.get("sortBy")}_${query.get("sortDir")}`
      )
    : { value: "", label: "" };
  const [sort, setSort] = useState(sortByDefault);
  const [success, setSuccess] = useState("");

  const getAndSetCross = () => {
    getCross({
      paginationCount: pageSize,
      paginationOffset: (currentPage - 1) * pageSize,
      search,
      sort,
    })
      .then((res) => {
        setCross(res.items);
        setCrossCount(res.totalItems);
      })
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const url = new URLSearchParams();
    if (currentPage !== 1) {
      url.append("page", currentPage);
    }

    if (search) {
      url.append("search", search);
    }

    if (sort.by && sort?.dir) {
      url.append("sortBy", sort.by);
      url.append("sortDir", sort.dir);
    }

    if (url) {
      history.push(`${pathname}?${url}`);
    } else {
      history.push(pathname);
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, search, sort]);

  useEffect(() => {
    setLoading(true);
    getAndSetCross();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, sort]);

  useEffect(() => {
    setIsShowReset(isSearch());
    if (!isSearch()) {
      setLoading(true);
      getAndSetCross();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const searchHandler = () => {
    if (!search) return;
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    setLoading(true);
    getAndSetCross();
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      e.preventDefault();
      searchHandler();
    }
  };

  const isSearch = () => {
    if (search) {
      return true;
    }
    return false;
  };

  const resetFilter = () => {
    setSearch("");
  };

  const selectHandler = (val, res) => {
    switch (res.name) {
      case "sort":
        setSort(val);
        break;
      default:
        break;
    }
  };

  const remove = (id) => {
    setLoading(true);
    removeCross({ id })
      .then((res) => {
        getAndSetCross();
        setSuccess("Файл и кроссы успешно удалены!");
      })
      .catch((e) => {});
  };

  return (
    <Container fluid className="p-0 cross">
      {loading && <MySpinner overlay />}
      {success}
      <Card className="mb-0">
        <CardBody>
          <h1 className="h3 mb-3">Кроссы</h1>
          <Form className="mb-3">
            <Row>
              <Col md="4">
                <Label>Поиск</Label>
                <InputGroup>
                  <Input
                    name="search"
                    placeholder="По артикулу и аналогу"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyPress={handleKeypress}
                  />
                  {isShowReset && (
                    <Button color="danger" onClick={() => resetFilter()}>
                      <FontAwesomeIcon icon={faWindowClose} />
                    </Button>
                  )}
                  <Button color="primary" onClick={searchHandler}>
                    Поиск
                  </Button>
                </InputGroup>
              </Col>

              <Col md="3">
                <Label>Сортировка</Label>
                <InputGroup>
                  <Select
                    name="sort"
                    placeholder="Сортировка"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={SORT_FIELDS}
                    value={sort}
                    defaultValue={[{ value: "", label: "" }]}
                    onChange={selectHandler}
                  />
                </InputGroup>
              </Col>
            </Row>
          </Form>
          <Link to="/reference/cross/upload" className="btn btn-primary">
            Загрузить кроссы
          </Link>
        </CardBody>
      </Card>

      <div class="react-bootstrap-table">
        <table class="table table-hover">
          <thead>
            <tr>
              <th style={{ width: "10%" }}>Артикул</th>
              <th style={{ width: "10%" }}>Бренд</th>
              <th style={{ width: "10%" }}>Артикул</th>
              <th style={{ width: "10%" }}>Бренд</th>
              <th style={{ width: "10%" }}>Код аналога</th>
              <th style={{ width: "7%" }}>Дата</th>
              <th style={{ width: "10%" }}>Файл</th>
              <th style={{ width: "5%" }}></th>
            </tr>
          </thead>
          <tbody>
            {cross.map((item) => (
              <tr key={item.id}>
                <td style={{ width: "10%" }}>{item.article}</td>
                <td style={{ width: "10%" }}>{item.brand}</td>
                <td style={{ width: "10%" }}>{item.articleAnalog}</td>
                <td style={{ width: "10%" }}>{item.brandAnalog}</td>
                <td style={{ width: "10%" }}>{item.analogueCode}</td>
                <td style={{ width: "7%" }}>
                  {dateTimeFormat(item.createdAt)}
                </td>
                <td style={{ width: "10%" }}>{item.file}</td>
                <td style={{ width: "5%" }}>
                  <Button color="link" onClick={() => remove(item.id)}>
                    <Trash color="red" size={20} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Card>
        <CardBody>
          <Pagination
            totalCount={crossCount}
            currentPage={currentPage}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            onChangePageSize={setPageSize}
          />
        </CardBody>
      </Card>
    </Container>
  );
};
